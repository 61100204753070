import React from "react";
import Layout from "../Components/layout";
import Cartinfo from "../Components/CartInfo";
import { Seo } from "../Components/seo";


export default function Cart() {
  return (
    <Layout>
      <Cartinfo />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)
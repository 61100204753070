import React, { useState, useEffect } from "react";
import { getCookie, updateCartCookie } from "../Utils/Cookies";
import { Fetcher, getFetcher } from "../Utils/AxiosWrapper";
import { getEmail } from "../Utils/isLoggedIn.js";
import useSWR from 'swr';
import useCurrentWidth from "./useCurrentWidth";
import Modal from "react-bootstrap/Modal";
import Context from "../Utils/context";
import { useContext } from "react";

export default function Cartinfo() {
    const [items, setItems] = useState([]);
    const [savedItems, setSavedItems] = useState([]);
    const [returnPolicy, setReturnPolicy] = useState(false);
    const width = useCurrentWidth();
    const context = useContext(Context);
    const { data } = useSWR("/api/SavedItems?email="+getEmail(), getFetcher)
    useEffect(() => {
        let cart = getCookie("cart");
        setItems(JSON.parse("["+cart+"]"));
      }, []);
      useEffect(() => {
        if (data)
        {
        setSavedItems(JSON.parse("["+data.savedInfo+"]"));
        }
      }, [data]);
      const calcTotal = function() {
          let total = 0;
          items.forEach((item) => {
            total = total + (parseInt(item.price) * parseInt(item.qty));
          })
          return total;
      }
      const updateSaved = function(updatedInfo) {
        Fetcher("POST", "/api/SavedItems",{Email: getEmail(), SavedInfo: updatedInfo})
        .then(() => {
        })
        .catch(() => {
        });
      }
    
  return (
      <>
      {width < 851 ? 
    <div style={{margin: `10px`}}>
        <h1 style={{fontSize: `20px`, lineHeight: `45px`, color: `#e37100`, marginBottom: `10px`}}>Shopping Cart</h1>
            <div style={{width: `100%`, minWidth: `255px`, borderTop: `2px solid #e37100`}}>
                {items.map((item, index) => (
                    <div key={item.name+index} style={{borderBottom: `2px solid #e37100`, margin: `10px 0px`}}><div style={{display: `flex`}}>
                        <div style={{margin: `15px 0px`}}><img style={{height: `15vh` }} alt="shopping cart item" src={item.image}/></div>
                        <div>
                       <div style={{marginTop: `10px`, color: `black`, fontSize: `18px`}}>{item.title}</div>
                       <div style={{marginTop: `10px`, color: `black`, fontSize: `18px`}}>Price: <span style={{color: `#2e10cf`}}>${parseInt(item.price) * parseInt(item.qty)}</span></div>
                       <div style={{display: `flex`, marginTop: `15px`}}><div style={{width: `100px`}}>Size: {item.size}</div><div>Qty: {item.qty}</div></div>
                        <button style={{ cursor: `pointer`, fontSize: `16px`, padding: `0px`, marginTop: `15px`, color: `white`}} onClick={() => {
                            let newItems = [].concat(savedItems);
                            let added = newItems.concat(item);
                            setSavedItems(added);
                            updateSaved(JSON.stringify(added).replace(/[[\]']+/g,''));
                            let newItems2 = [].concat(items);
                            newItems2.splice(index,1);
                            setItems(newItems2);
                            updateCartCookie("cart", JSON.stringify(newItems2).replace(/[[\]']+/g,''));
                            context.update_count(context.cartCount-1);
                        }}>Save for later</button>
                        <button style={{cursor: `pointer`, fontSize: `16px`, padding: `0px`, margin: `15px 0px`, display: `block`, color: `white`}} onClick={() => {
                            let newItems = [].concat(items);
                            newItems.splice(index,1);
                            setItems(newItems);
                            updateCartCookie("cart", JSON.stringify(newItems).replace(/[[\]']+/g,''));
                            context.update_count(context.cartCount-1);
                        }}>Delete</button>
                       </div>
                   </div>
                   </div>
                ))}
                    <div style={{marginTop: `20px`, display: `flex`, justifyContent: `flex-end`, alignItems: `center` }}>
                        <p style={{ marginTop: `10px`, fontSize: `20px`, color: `black`}}>Subtotal: {items.length} item(s): <span style={{color: `#2e10cf`}}>${calcTotal()}</span></p>
                </div>
            </div>
            <div>
                    <div style={{ display: `flex`, justifyContent: `space-between`, alignItems: `center`, marginTop: `50px`, fontSize: `14px` }}>
                    <button onClick={() => {setReturnPolicy(!returnPolicy);}} style={{ padding: `5px`, fontWeight: `bold`, color: `white`, cursor: `pointer`, textTransform: `uppercase`}}>
                            Return Policy
                        </button>
                        {(items.length > 0) ? <><button onClick={() => {window.location.href = "/shipping"}} style={{ padding: `5px`, fontWeight: `bold`, color: `white`, cursor: `pointer`, textTransform: `uppercase`}}>
                            Proceed to Checkout
                        </button></> : <div style={{color: `black`, fontSize: `12px`}}>Add items to the cart to checkout.</div>} 
                    </div>
            </div>



        {(savedItems.length > 0) && <><div style={{fontSize: `20px`, lineHeight: `45px`, color: `white`, marginTop: `100px`}}>Saved For Later</div>
            <div style={{width: `100%`, minWidth: `255px`, borderTop: `2px solid #e37100`}}>
                {savedItems.map((item, index) => (
                    <div key={"saved"+item.name+index} style={{borderBottom: `2px solid #e37100`, margin: `10px 0px`}}><div style={{display: `flex`}}>
                    <div style={{margin: `15px 0px`}}><img alt="product" style={{height: `15vh` }} src={item.image}/></div>
                    <div>
                   <div style={{marginTop: `10px`, color: `black`, fontSize: `18px`}}>{item.title}</div>
                   <div style={{marginTop: `10px`, color: `black`, fontSize: `18px`}}>Price: <span style={{color: `#2e10cf`}}>${parseInt(item.price) * parseInt(item.qty)}</span></div>
                   <div style={{display: `flex`, marginTop: `20px`, color: `#dddddd`}}><div style={{width: `100px`}}>Size: {item.size}</div><div>Qty: {item.qty}</div></div>
                        <button style={{cursor: `pointer`, fontSize: `16px`, padding: `0px`, color: `white`, marginTop: `15px`}} onClick={() => {
                            let newItems = [].concat(items);
                            let added = newItems.concat(item);
                            setItems(added);
                            updateCartCookie("cart", JSON.stringify(added).replace(/[[\]']+/g,''));
                            let newItems2 = [].concat(savedItems);
                            newItems2.splice(index,1);
                            setSavedItems(newItems2);
                            updateSaved(JSON.stringify(newItems2).replace(/[[\]']+/g,''));
                            context.update_count(context.cartCount+1);
                        }}>Add to cart</button>
                        <button style={{cursor: `pointer`, fontSize: `16px`, padding: `0px`, color: `white`, margin: `15px 0px`, display: `block`}} onClick={() => {
                            let newItems = [].concat(savedItems);
                            newItems.splice(index,1);
                            setSavedItems(newItems);
                            updateSaved(JSON.stringify(newItems).replace(/[[\]']+/g,''));
                        }}>Delete</button>
                       </div>
                   </div>
                   </div>
                ))}
            </div>
        </>}
      </div> : 
          <div style={{margin: `50px`}}>
          <div style={{fontSize: `30px`, lineHeight: `45px`, color: `#e37100`, marginBottom: `10px`}}>Shopping Cart</div>
          <div style={{display: `flex`}}>
              <div style={{width: `65%`, minWidth: `255px`, borderTop: `2px solid #e37100`}}>
                  {items.map((item, index) => (
                      <div key={"large"+item.name+index} style={{borderBottom: `2px solid #e37100`, margin: `10px 0px`}}><div style={{display: `flex`}}>
                          <div style={{margin: `15px 0px`}}><img alt="product" style={{height: `25vh` }} src={item.image}/></div>
                          <div><div style={{display: `flex`}}>
                         <div style={{marginTop: `10px`, color: `black`, fontSize: `18px`}}>{item.title}</div>
                         <div style={{marginTop: `10px`, color: `black`, fontSize: `18px`, marginLeft: `50px`}}>Price: <span style={{color: `#2e10cf`}}>${parseInt(item.price) * parseInt(item.qty)}</span></div></div>
                         <div style={{display: `flex`, marginTop: `20px`, color: `black`}}><div style={{width: `160px`}}>Size: {item.size}</div><div>Qty: {item.qty}</div></div>
                         <div style={{marginTop: `20px`, display: `flex`, marginLeft: `-6px`}}>
                          <button style={{ cursor: `pointer`, fontSize: `16px`, color: `white`}} onClick={() => {
                              let newItems = [].concat(savedItems);
                              let added = newItems.concat(item);
                              setSavedItems(added);
                              updateSaved(JSON.stringify(added).replace(/[[\]']+/g,''));
                              let newItems2 = [].concat(items);
                              newItems2.splice(index,1);
                              setItems(newItems2);
                              updateCartCookie("cart", JSON.stringify(newItems2).replace(/[[\]']+/g,''));
                              context.update_count(context.cartCount+1);
                          }}>Save for later</button>
                          <button style={{ cursor: `pointer`, fontSize: `16px`, color: `white`, marginLeft: `40px`}} onClick={() => {
                              let newItems = [].concat(items);
                              newItems.splice(index,1);
                              setItems(newItems);
                              updateCartCookie("cart", JSON.stringify(newItems).replace(/[[\]']+/g,''));
                              context.update_count(context.cartCount-1);
                          }}>Delete</button>
                          </div>
                         </div>
                     </div>
                     </div>
                  ))}
                      <div style={{ display: `flex`, justifyContent: `space-between`, marginTop: `20px` }}>
                          <button onClick={() => {setReturnPolicy(!returnPolicy);}} style={{ padding: `10px`, fontWeight: `bold`, color: `white`, cursor: `pointer`, textTransform: `uppercase`}}>
                              Return Policy
                          </button>
                          <div><p style={{ marginTop: `10px`, fontSize: `20px`, color: `black`}}>Subtotal: {items.length} item(s): <span style={{color: `#2e10cf`}}>${calcTotal()}</span></p></div>
                  </div>
              </div>
              <div>
                  <div style={{ marginLeft: `10vw`}}>
                      <div style={{ display: `flex`, justifyContent: `center`, alignItems: `center` }}>
                          {(items.length > 0) ? <><button onClick={() => {window.location.href = "/shipping"}} style={{ padding: `10px`, fontWeight: `bold`, color: `white`, cursor: `pointer`, textTransform: `uppercase`}}>
                              Proceed to Checkout
                          </button></> : <div style={{color: `black`}}>Please add items to the cart to checkout.</div>} 
                          
                      </div>
                      <div style={{ display: `flex`, justifyContent: `center`, alignItems: `center` }}>
                  <p style={{ marginTop: `10px`, fontSize: `16px`, color: `black`}}>Subtotal: {items.length} item(s): <span style={{color: `#2e10cf`}}>${calcTotal()}</span></p></div>
                  </div>
              </div>
          </div>
  
  
  
          {(savedItems.length > 0) && <><div style={{fontSize: `30px`, lineHeight: `45px`, color: `black`, marginTop: `100px`}}>Saved For Later</div>
          <div style={{display: `flex`}}>
              <div style={{width: `65%`, minWidth: `255px`, borderTop: `2px solid #e37100`}}>
                  {savedItems.map((item, index) => (
                      <div key={"largesaved"+item.name+index} style={{borderBottom: `2px solid #e37100`, margin: `10px 0px`}}><div style={{display: `flex`}}>
                          <div style={{margin: `15px 0px`}}><img alt="product" style={{height: `25vh` }} src={item.image}/></div>
                          <div><div style={{display: `flex`}}>
                         <div style={{marginTop: `10px`, color: `black`, fontSize: `18px`}}>{item.title}</div>
                         <div style={{marginTop: `10px`, color: `whblackite`, fontSize: `18px`, marginLeft: `50px`}}>Price: <span style={{color: `#2e10cf`}}>${parseInt(item.price) * parseInt(item.qty)}</span></div></div>
                         <div style={{display: `flex`, marginTop: `20px`, color: `black`}}><div style={{width: `160px`}}>Size: {item.size}</div><div>Qty: {item.qty}</div></div>
                         <div style={{color: `black`, marginTop: `20px`, display: `flex`, marginLeft: `-6px`}}>
                          <button style={{cursor: `pointer`, fontSize: `16px`, color: `white`}} onClick={() => {
                              let newItems = [].concat(items);
                              let added = newItems.concat(item);
                              setItems(added);
                              updateCartCookie("cart", JSON.stringify(added).replace(/[[\]']+/g,''));
                              let newItems2 = [].concat(savedItems);
                              newItems2.splice(index,1);
                              setSavedItems(newItems2);
                              updateSaved(JSON.stringify(newItems2).replace(/[[\]']+/g,''));
                              context.update_count(context.cartCount+1);
                          }}>Add to cart</button>
                          <button style={{cursor: `pointer`, fontSize: `16px`, color: `white`, marginLeft: `61px`}} onClick={() => {
                              let newItems = [].concat(savedItems);
                              newItems.splice(index,1);
                              setSavedItems(newItems);
                              updateSaved(JSON.stringify(newItems).replace(/[[\]']+/g,''));
                          }}>Delete</button>
                          </div>
                         </div>
                     </div>
                     </div>
                  ))}
              </div>
          </div></>}
        </div> }
        <Modal
        className="termsModal"
        style={{ border: `none` }}
        show={returnPolicy}
        onHide={() => {setReturnPolicy(!returnPolicy)}}
        centered
        scrollable={true}
      >
        <Modal.Header
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255,0,0), rgba(252,195,10))`,
            height: `40px`,
            fontWeight: `bold`,
            color: `white`,
            border: `none`,
            
          }}
          closeButton={false}
        >
          <Modal.Title className="modalHeader">
            Return Policy
          </Modal.Title>
          <div
            role="button"
            tabIndex="0"
            onKeyUp={(e) => {
                if (e.key ==="Enter")
                {
                    setReturnPolicy(!returnPolicy);
                }
            }}
            onClick={() => {setReturnPolicy(!returnPolicy)}}
          >
          <img
            style={{
              marginTop: `-6px`,
              marginRight: `-10px`,
              cursor: "pointer",
              height: `20px`,
            }}
            src={window.location.origin + "/Images/closeTerms.png"}
            alt="close button"
          />
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            background: `linear-gradient(rgba(255, 100, 0, 0.2), rgba(252, 195, 10, 0.2))`,
            border: `none`,
            maxHeight: `80vh`,
            overflowY: `auto`,
          }}
          className="loginModalBody"
        >
            <h1>Return Policy</h1>

            <p>
                Any claims for misprinted/damaged/defective items must be submitted within 4 weeks after the product has been received. <br/>
                For packages lost in transit, all claims must be submitted no later than 4 weeks after the estimated delivery date. Claims deemed an error on our part are covered at our expense.
            </p>
            <p>
                If you notice an issue on the products or anything else on the order, please submit a problem report.
            </p>
            <p>
                If the item is returned to our facility, someone from our team will contact you regarding the return. <br/>
                We will ship the return back to you; however, you will be responsible for the shipping cost a second time. <br/> 
                Unclaimed returns get donated to charity after 4 weeks.
            </p>
            <p>
                <b>Wrong Address</b> - If you provide an address that is considered insufficient by the courier, the shipment will be returned to our facility. <br/>
                You will be liable for reshipment costs once we have confirmed an updated address with you (if and as applicable). 
            </p>
            <p>
                <b>Unclaimed</b> - Shipments that go unclaimed are returned to our facility and you will be liable for the cost of a reshipment to yourself (if and as applicable).
            </p>
            <p>
                If you haven't registered an account on StrongerThanMed.com and added a billing method, you hereby agree that any returned orders due to the wrong shipping address <br/>
                or a failure to claim the shipment won’t be available for reshipping and will be donated to charity at your cost (without us issuing a refund).
            </p>
            <p>
                Stronger Than Medicine does not accept returns of sealed goods, such as but not limited to face masks, which are not suitable for return due to health or hygiene reasons. <br/>
                You hereby agree that any returned orders with face masks won’t be available for reshipping and will be disposed of.
            </p>
            <p>
                This Policy shall be governed and interpreted in accordance with the English language, regardless of any translations made for any purpose whatsoever.
            </p>
        <h1> How to Report a Problem </h1>

        <ol>
            <li>Log into the account you provided us or onto your Stronger Than Medicine account.</li>
            <li>Find your order number.</li>
            <li>On the Stronger Than Medicine website, click on "report an issue" found in the footer at the bottom of the page</li>
            <li>Provide us with the order number you recieved.</li>
            <li>List the issues regarding your order in detail and upload relevant photos.</li>
            <li>Submit your report by clicking on the "Report Problem" button provided.</li>
        </ol>

        <p>
            Upon reviewing your order claims and if we find that the issues regarding your purchase where indeed our fault, a reshipment
            of your purchase will be sent out.
        </p>
        </Modal.Body>
      </Modal>
      </>
  );
};

